import { Environment } from './environment.types';

// https://sandbox-api.bannerflow.com/user-activity-service/user-activity/hub
// SubscribeToAccountActivity <- accountId

export const environment: Environment = {
    loginUrl: 'https://sandbox-login.bannerflow.com',
    clientId: 'r7IvQIuyD9wAfhcTS8cYGsFOx91oBqYL',
    origins: {
        userActivityService: 'https://sandbox-api.bannerflow.com/user-activity-service'
    }
};
